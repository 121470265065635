<template>
	<div>
		<div class="money_style">
			<div class="total_money v_x_center" style="float: left;">
				<div>{{cpsAllMoney || 0}}</div>
				<div>总收益</div>
			</div>
			<div class="total_money v_x_center">
				<div>{{t_cps_balance || 0}}</div>
				<div>账户余额</div>
			</div>
		</div>
		
		
		
		<div class="buttons x_start_v">
			<el-button size="small" type="primary" @click="showDialog('applyMoneyShow')">申请提现</el-button>
			<el-button size="small" type="primary" @click="showDialog('settingMehodsShow')">设置提现账号</el-button>
		</div>

		<el-table :data="list" v-loading="loadingShow" border style="width: 100%">
			<el-table-column prop="t_put_money" label="提现金额"></el-table-column>
			<el-table-column prop="name" label="提现方式">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.t_put_type == 0">支付宝</div>
						<div v-if="scope.row.t_put_type == 1">微信</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="t_zhifubao_name" label="支付宝姓名"></el-table-column>
			<el-table-column prop="t_zhifubao_number" label="支付宝账号"></el-table-column>
			<el-table-column prop="province" label="二维码">
				<template slot-scope="scope">
					<div class="list_img_box">
						<img :src="scope.row.t_zhifubao_qrCode" alt="">
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="city" label="状态">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.t_put_status == 1">已申请</div>
						<div v-if="scope.row.t_put_status == 2">已打款</div>
						<div v-if="scope.row.t_put_status == 3">已拒绝</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="t_create_time" label="申请时间">
			</el-table-column>
			<el-table-column prop="t_put_time" label="打款时间">
			</el-table-column>
		</el-table>
		<el-pagination background layout="total, prev, pager, next, jumper"
			:page-size="param.pageSize" 
			:current-page.sync="param.page" 
			:total="total" 
			@current-change="currentChange"></el-pagination>
		
		<applyMoney :title="'申请提现'" :pageShow="dialog.applyMoneyShow" :t_cps_balance="t_cps_balance" @close="showDialog('applyMoneyShow')" @apply-success="showDialog('applyMoneyShow')"></applyMoney>
		<settingMehods :title="'设置提现账号'" :pageShow="dialog.settingMehodsShow"  @close="showDialog('settingMehodsShow')" @apply-success="applaySuccess"></settingMehods>
	</div>
</template>

<script>
	
	import applyMoney from "../components/applyMoney.vue"
	import settingMehods from "../components/settingMehods.vue"
	export default{
		components:{
			applyMoney,
			settingMehods
		},
		data(){
			return{
				dialog:{
					applyMoneyShow:false,
					settingMehodsShow:false
				},
				param: {
					page:1,  //   1
					pageSize:10,  //   10
				},
				searchserId:null,
				beginTime:null,  //  	开始时间
				endTime:null,  //  	结束时间
				loadingShow:false,
				list: [],
				total:0,
				t_cps_balance:null,
				cpsAllMoney:null
			}
		},
		mounted() {
			this.getList()
		},
		methods:{
			applaySuccess(){
				console.log("applaySuccess")
				this.showDialog('settingMehodsShow')
				
				this.getList()
			},
			async getList(){
				console.log("save")
				let that = this
				let param = {
					url: "myPutMoneyList",
					data: this.param
				}
				this.loadingShow = true
				await this.api(param).then(res => {
					that.list = []
					if(res.status == 0){
						that.list = res.data.walletList.list
						that.total = res.data.walletList.total
						that.cpsAllMoney = res.data.cpsBalance.cpsAllMoney
						that.t_cps_balance = res.data.cpsBalance.t_cps_balance
					}
				})
				this.loadingShow = false
			},
			currentChange(page){
				this.param.page = page
				this.getList()
			},
			showDialog(dialogName){
				console.log("关闭",dialogName)
				this.dialog[dialogName] = !this.dialog[dialogName]
			}
		}
	}
</script>

<style scoped="">
	
	.money_style{
		
		width: 800px;
	}
	.total_money{
		background-color: rgb(103, 194, 58);
		width: 340px;
		height: 130px;
		border-radius: 10px;
		color: white;
	}
	.total_money>div:nth-of-type(1){
		font-size: 40px;
		margin-bottom: 10px;
	}
	.total_money>div:nth-of-type(2){
		font-size: 15px;
	}
	.buttons{
		margin-top: 20px;
		margin-bottom: 20px;
	}
</style>
