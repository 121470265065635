<template>
	<div v-if="pageShow">
		<my-dialog :title="title" :pageShow="pageShow" @close="close_dialog">
			<div class="content">
				<el-form :model="param" :rules="rules" ref="param" label-position="left" label-width="100px" class="demo-ruleForm">
					<el-form-item label="可提现金额">
						<el-input v-model="t_cps_balance" disabled></el-input>
					</el-form-item>
					<el-form-item label="提现金额" prop="t_put_money">
						<el-input-number v-model="param.t_put_money" :min="0.1" :max="t_cps_balance" controls-position="right"></el-input-number>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="loadingShow" @click="submitForm('param')">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</my-dialog>
	</div>

</template>

<script>
	export default {
		props: {
			t_cps_balance: {
				type: Number
			},
			pageShow: {
				type: Boolean
			},
			title: {
				type: String
			}
		},
		data() {
			return {
				param: {
					t_put_money: 0
				},
				rules: {
					t_put_money: [{
						required: true,
						message: '请输入提现金额',
						trigger: 'blur'
					}]
				},
				loadingShow: false
			}
		},
		mounted() {
		
		},
		methods: {
			submitForm(formName) {
				let that = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						that.save()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async save() {
				console.log("save")
				let that = this
				let param = {
					url: "addPutMoneyInfo",
					data: this.param
				}
				this.loadingShow = true
				await this.api(param).then(res => {
					that.list = []
					if (res.status == 0) {
						that.$message({
							message: '提交成功',
							type: 'success'
						});
						that.$emit("apply-success")
					}
				})
				this.loadingShow = false
			},
			close_dialog() {
				console.log("222222")
				this.$emit("close")
			},
		}
	}
</script>

<style scoped="">
	.content {
		padding: 20px 40px !important;
	}
</style>
